import './styles/BackgroundStyle.css'

export function Background() {
  return (
    <>
    <div class="bg"></div>
    <div class="bg bg2"></div>
    <div class="bg bg3"></div>
    </>
  );
}