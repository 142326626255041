import { Form, Button, Container, Row, Col } from "react-bootstrap";


export function ContactForm() {
  return (
    <Container>
    <Row className="justify-content-center bg-dark rounded py-5 px-3">
        <h1 className="text-center text-white w-100">Contact Me</h1>
        <Col md={11}>
            <Form action="https://formspree.io/f/xaygoqay" method="POST">
                <Form.Group className="mb-3">
                    <Form.Label className="text-white">Name</Form.Label>
                    <Form.Control type="text" name="name" required />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label className="text-white">Email</Form.Label>
                    <Form.Control type="email" name="_replyto" required />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label className="text-white">Message</Form.Label>
                    <Form.Control as="textarea" name="message" rows={3} required />
                </Form.Group>

                <Button variant="light" type="submit">
                    Send
                </Button>
            </Form>
        </Col>
    </Row>
</Container>

  );
}
